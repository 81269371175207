/* TableComponent.css */

.table-container {
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  max-height: 550px;
  margin-left: 4em;
  margin-right: 4em;
  margin-bottom: 4em;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  -webkit-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
  -moz-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
  box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 0px solid #ddd;
  padding: 8px;
  text-align: center;
  min-width: 170px;
}

.custom-table th {
  background-color: #f2f2f2;
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table tbody tr:hover {
  background-color: #ddd;
}

.table-responsive-svg {
  width: 90%;
  height: 40px;
}

.table-header-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-order-button {
  display: inline-block;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  padding-right: 0.9em;
  padding-left: 0.9em;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  font-family: "League Spartan";
  text-decoration: none;
  color: rgb(255, 255, 255);
  background-color: rgb(56, 182, 255); /* Background color */
  box-shadow: inset 0 -3em 3em rgb(56, 182, 255), 0 0 0 1px #05a3ff,
    0.1em 0.1em 0.4em #333;
  border-radius: 15px;
  margin-top: 0.8em;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    transform 0.3s ease;
}

.table-order-button:hover {
  transform: scale(1.05);
}

.cell-no-border {
  /* background-color: #fff !important; */
  z-index: 5 !important;
}

table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}

@media (min-width: 700px) and (max-width: 999px) {
  .table-container {
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-height: 450px;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 4em;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    -webkit-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
    -moz-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
    box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
  }

  .custom-table th,
  .custom-table td {
    border: 0px solid #ddd;
    padding: 7px 2px;
    text-align: center;
    min-width: 100px;
    font-size: 14px;
  }

  .table-responsive-svg {
    width: 58%;
    height: 40px;
  }

  .table-order-button {
    display: inline-block;
    padding-bottom: 0.4em;
    padding-top: 0.4em;
    padding-right: 0.7em;
    padding-left: 0.7em;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    font-family: "League Spartan";
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: rgb(56, 182, 255); /* Background color */
    box-shadow: inset 0 -3em 3em rgb(56, 182, 255), 0 0 0 1px #05a3ff,
      0.1em 0.1em 0.4em #333;
    border-radius: 15px;
    margin-top: 0.8em;
    transition: background-color 0.3s ease, border-color 0.3s ease,
      transform 0.3s ease;
  }
}

@media (min-width: 1000px) and (max-width: 1130px) {
  .table-container {
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-height: 450px;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 4em;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    -webkit-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
    -moz-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
    box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
  }

  .custom-table th,
  .custom-table td {
    border: 0px solid #ddd;
    padding: 8px 3px;
    text-align: center;
    min-width: 120px;
  }

  .table-responsive-svg {
    width: 58%;
    height: 40px;
  }
}

@media (min-width: 1131px) and (max-width: 1274px) {
  .table-container {
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-height: 450px;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 4em;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    -webkit-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
    -moz-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
    box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
  }

  .custom-table th,
  .custom-table td {
    border: 0px solid #ddd;
    padding: 8px 4px;
    text-align: center;
    min-width: 150px;
    font-size: 1;
  }

  .table-responsive-svg {
    width: 60%;
    height: 40px;
  }
}

@media (min-width: 1131px) and (max-width: 1350px) {
  .table-container {
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-height: 550px;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 4em;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    -webkit-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
    -moz-box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
    box-shadow: 7px 8px 24px -12px rgba(113, 114, 125, 1);
  }
}

tr > th:first-child,
tr > td:first-child {
  position: sticky;
  left: 0;
  background-color: #f2f2f2;
  z-index: 2; /* Ustawiamy z-index, aby komórka była widoczna nad innymi elementami */

  width: 310px !important; /* it must be flexible MediaQuery */
}

@media (min-width: 700px) and (max-width: 999px) {
  tr > th:first-child,
  tr > td:first-child {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    z-index: 2; /* Ustawiamy z-index, aby komórka była widoczna nad innymi elementami */

    width: 250px !important; /* it must be flexible MediaQuery */
  }
}

.header-option-number {
  font-size: 32px;
  font-weight: bold;
  color: #2e3b4e;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
}

.header-option-number-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.payment-provider-button {
  padding: 12px 16px 12px 16px;
  font-size: 1.1em;
  color: rgb(255, 255, 255);
  background-color: rgb(56, 182, 255);
  border: none;
  margin-top: 4px;
  font-weight: 400;
  cursor: pointer;
  font-family: "League Spartan";
  border-radius: 30px;
  box-shadow: inset 0 -3em 3em rgb(56, 182, 255), 0 0 0 1px #05a3ff,
    0.1em 0.1em 0.4em #333;
}

.payment-provider-button:hover {
  transform: scale(1.05);
}
