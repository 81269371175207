.custom-normal-long {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 195px;
  margin-left: 35px;
  margin-top: 3.5em;
  line-height: 1.5em;
}

@media (max-width: 760px) {
  .custom-normal-long {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    margin-left: 35px;
    margin-top: 3.5em;
    line-height: 1.5em;
  }
}

@media (min-width: 761px) and (max-width: 900px) {
  .custom-normal-long {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 85px;
    margin-left: 35px;
    margin-top: 3.5em;
    line-height: 1.5em;
  }
}

.custom-bold {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 255px;
  margin-left: 35px;
  margin-top: 2em;
  line-height: 1.5em;
}

@media (max-width: 760px) {
  .custom-bold {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    margin-left: 35px;
    margin-top: 2em;
    line-height: 1.5em;
  }
}

@media (min-width: 761px) and (max-width: 900px) {
  .custom-bold {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 145px;
    margin-left: 35px;
    margin-top: 2em;
    line-height: 1.5em;
  }
}

.custom-bold-long {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 210px;
  margin-left: 35px;
  margin-top: 2em;
  line-height: 1.5em;
}

@media (max-width: 760px) {
  .custom-bold-long {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    margin-left: 35px;
    margin-top: 2em;
    line-height: 1.5em;
  }
}

@media (min-width: 761px) and (max-width: 900px) {
  .custom-bold-long {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 100px;
    margin-left: 35px;
    margin-top: 2em;
    line-height: 1.5em;
  }
}

.text-left {
  text-align: left;
  margin: 0;
  max-width: 610px;
  font-weight: 300;
  font-family: "League Spartan";
}

.text-bold {
  text-align: left;
  margin: 0;
  max-width: 610px;
  font-weight: 700;
  font-family: "League Spartan";
}

.text-normal-long {
  text-align: left;
  margin: 0;
  max-width: 670px;
  font-weight: 500;
  font-family: "League Spartan";
  position: relative;
}

@media (max-width: 760px) {
  .text-normal-long {
    text-align: left;
    margin: 0;
    max-width: 625px;
    font-weight: 500;
    font-family: "League Spartan";
    position: relative;
  }
}

.text-bold-long {
  text-align: left;
  margin: 0;
  max-width: 655px;
  font-weight: 700;
  font-family: "League Spartan";
}

@media (max-width: 760px) {
  .text-bold-long {
    text-align: left;
    margin: 0;
    max-width: 610px;
    font-weight: 700;
    font-family: "League Spartan";
  }
}

@media (max-width: 349px) {
  .background-arrow {
    position: absolute;
    bottom: -45px;
    left: -35px;
    z-index: -1;
    max-height: 55px;
    transform: rotate(29deg);
  }
}

@media (min-width: 350px) and (max-width: 549px) {
  .background-arrow {
    position: absolute;
    bottom: -55px;
    left: -35px;
    z-index: -1;
    max-height: 65px;
    transform: rotate(29deg);
  }
}

@media (min-width: 550px) and (max-width: 760px) {
  .background-arrow {
    position: absolute;
    bottom: -65px;
    left: -35px;
    z-index: -1;
    max-height: 75px;
    transform: rotate(29deg);
  }
}

@media (min-width: 761px) and (max-width: 950px) {
  .background-arrow {
    position: absolute;
    bottom: -65px;
    left: -35px;
    z-index: -1;
    max-height: 75px;
    transform: rotate(29deg);
  }
}

@media (min-width: 951px) {
  .background-arrow {
    position: absolute;
    bottom: -75px;
    left: -45px;
    z-index: -1;
    max-height: 85px;
    transform: rotate(29deg);
  }
}
