.custom-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.7em;
}

.subtitle-centered-text {
  text-align: center;
  margin: 0;
  max-width: 545px;
  font-weight: 500;
  font-family: "League Spartan";
}

.subtitle-centered-text-gradient {
  max-width: 475px;
}

.gradient-span {
  display: inline-block;
  background: linear-gradient(
    to right,
    #ddfee6,
    #bad2ff
  ); /* Gradient from pink to grey */
}
