@media (min-width: 451px) {
  #nav-mobile {
    display: none;
  }
}

ul.mobile-menu-list {
  list-style: none;
  padding: 0;
  position: absolute; /* Ustaw pozycję absolutną */
  top: 76px; /* Umieść menu pod hamburgerem */
  left: 0;
  background-color: #333;
  opacity: 0.98;
  margin: 0;
  width: 100%;
  text-align: center;
}

ul.mobile-menu-list > li > a {
  text-decoration: none;
  color: #fff;
  font-family: "League Spartan";
}
