/* Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "League Spartan";
  padding: 2px 10px 2px 2px;
  background-color: #333;
  color: #fff;
  width: 100%;
  box-shadow: 0 0 4px 0 #333, 0 2px 4px 0#333;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.static-tabs ul,
.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.static-tabs li {
  display: inline;
  margin-right: 20px;
}

.static-tabs a,
.dropdown-menu a {
  color: #fff;
  text-decoration: none;
}

.hamburger-menu {
  font-size: 24px;
  cursor: pointer;
  display: block; /* Wyświetl ikonę hamburgera jako blok */
}

.dropdown-menu {
  position: absolute; /* Ustaw pozycję absolutną */
  top: 76px; /* Umieść menu pod hamburgerem */
  right: 0;
  background-color: #333;
  opacity: 0.98;
}

.dropdown-menu.open {
  display: block;
}

@media (max-width: 450px) {
  #desktop-menu {
    display: none;
  }
}
