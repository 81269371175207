@media (max-width: 800px) {
  #domain-tab {
    display: none;
  }
}

@media (max-width: 650px) {
  #account-tab {
    display: none;
  }
}

@media (max-width: 500px) {
  #accounting-tab {
    display: none;
  }
}

@media (max-width: 450px) {
  #payment-tab,
  #hosting-tab {
    display: none;
  }
}

.static-tab-link:hover {
  font-size: 18px;
}
