.custom-dropdown-item {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 1px solid #ccc;
  background-color: #333;
}

#domain-nav {
  padding-bottom: 14px;
}

@media (min-width: 801px) {
  #domain-nav {
    display: none;
  }
}

@media (max-width: 801px) {
  .custom-dropdown-item-fixed {
    background-color: rgb(153, 153, 153);
  }
}

@media (min-width: 651px) {
  #account-nav {
    display: none;
  }
}

@media (min-width: 501px) {
  #accounting-nav {
    display: none;
  }
}
.custom-dropdown-item:hover {
  transform: scale(1.05) !important;
}
