.filter-checkboxes {
  display: flex;
  flex-direction: column;
  margin-right: 0.6em;
}

.filter-values {
  display: flex;
  flex-direction: column;
}

.filter-parameters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 300px) and (max-width: 493px) {
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    font-family: "League Spartan";
  }

  .filter-parameters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .filter-checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-values {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 494px) and (max-width: 534px) {
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
    margin-top: 2em;
    font-family: "League Spartan";
  }

  .filter-values {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  }

  .filter-checkboxes {
    display: flex;
    flex-direction: column;
    margin-right: 0.2em;
  }
}

@media (min-width: 535px) and (max-width: 610px) {
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
    margin-top: 2em;
    font-family: "League Spartan";
  }
  .filter-values {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  }
}

@media (min-width: 611px) and (max-width: 884px) {
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
    margin-top: 2em;
    font-family: "League Spartan";
  }
}

@media (min-width: 885px) and (max-width: 1000px) {
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    margin-top: 2em;
    font-family: "League Spartan";
  }
}

@media (min-width: 1001px) and (max-width: 1125px) {
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 60px;
    margin-top: 2em;
    font-family: "League Spartan";
  }
}

@media (min-width: 1126px) {
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    font-family: "League Spartan";
    margin-right: 170px;
    margin-left: 40px;
  }
}

.filter-values-textfield {
  margin: 0 0 0 0.5em !important;
  max-width: 200px;
}

.filter-values-textfield-next {
  margin: 1em 0 0 0.5em !important;
  max-width: 200px;
}

.filter-checkbox {
  margin: 0.5em !important;
}

.filter-values-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
