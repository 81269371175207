.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.custom-header {
  background-color: red;
  flex-direction: row;
  display: flex;
}

@media (min-width: 451px) {
  #mobile-menu {
    display: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.payment-check-button-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.4em;
  padding-bottom: 2.5em;
  border-bottom: 1px dashed lightgray;
}

.payment-check-button {
  padding: 18px 28px 18px 28px;
  font-size: 1.1em;
  color: rgb(255, 255, 255);
  background-color: rgb(56, 182, 255);
  border: none;
  font-weight: 700;
  cursor: pointer;
  font-family: "League Spartan";
  border-radius: 30px;
  box-shadow: inset 0 -3em 3em rgb(56, 182, 255), 0 0 0 1px #05a3ff,
    0.1em 0.1em 0.4em #333;
}

.payment-check-button:hover {
  transform: scale(1.05);
}

@media (max-width: 349px) {
  .background-card {
    display: none;
  }
}

@media (min-width: 350px) and (max-width: 420px) {
  .background-card {
    top: 525px;
    position: absolute;
    left: 50%;
    margin-left: 80px;
    z-index: -1;
    max-height: 100px;
  }
}

@media (min-width: 421px) and (max-width: 450px) {
  .background-card {
    top: 470px;
    position: absolute;
    left: 50%;
    margin-left: 120px;
    z-index: -1;
    max-height: 100px;
  }
}

@media (min-width: 451px) and (max-width: 540px) {
  .background-card {
    top: 460px;
    position: absolute;
    left: 50%;
    margin-left: 130px;
    z-index: -1;
    max-height: 100px;
  }
}

@media (min-width: 541px) and (max-width: 740px) {
  .background-card {
    top: 420px;
    position: absolute;
    left: 50%;
    margin-left: 165px;
    z-index: -1;
    max-height: 120px;
  }
}

@media (min-width: 741px) {
  .background-card {
    top: 350px;
    position: absolute;
    left: 50%;
    margin-left: 250px;
    z-index: -1;
    max-height: 140px;
  }
}

.tableViewRef {
  padding-top: 0.1em;
}

/* Scrollbar styles */
.table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background: rgb(56, 182, 255);
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: rgb(56, 182, 255);
}

.filter-container {
  display: flex;
}

.check-savings-button {
  color: rgb(255, 255, 255);
  background-color: rgb(56, 182, 255);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  padding: 14px 28px;
  border: none;
  border-radius: 50px;

  cursor: pointer;
  display: inline-block;
  box-shadow: inset 0 -3em 3em rgb(56, 182, 255), 0 0 0 1px #05a3ff,
    0.1em 0.1em 0.4em #333;
  margin: 48px;
}

.check-savings-button:hover {
  transform: scale(1.05);
}

@media (max-width: 800px) {
  .filter-container {
    flex-direction: column;
  }
}

.margin-top-40 {
  margin-top: 40px;
}

.blue-text {
  color: #05a3ff;
}

.Mui-error {
  font-style: italic;
}

.MuiInputLabel-root {
  font-style: italic;
}

.MuiInputBase-root {
  font-style: italic;
}
