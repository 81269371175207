.custom-title {
  line-height: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

@media (min-width: 437px) {
  .custom-title {
    line-height: 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4em;
  }
}

.centered-text {
  text-align: center;
  margin: 0;
  max-width: 470px;
  font-family: "League Spartan";
}

@media (min-width: 1px) and (max-width: 436px) {
  .background-line {
    border-left: 1px solid #1a1a1a;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    opacity: 0.4;
    height: 2em;
  }
}

@media (min-width: 437px) and (max-width: 529px) {
  .background-line {
    border-left: 1px solid #1a1a1a;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    opacity: 0.4;
    height: 2.5em;
  }
}

@media (min-width: 530px) {
  .background-line {
    border-left: 1px solid #1a1a1a;
    margin-top: 0.15em;
    margin-bottom: 0.15em;
    opacity: 0.4;
    height: 3em;
  }
}
